.container {
  width: auto;
  flex: 1;
  border-radius: 8px;
  border: 1px solid #d3dae2;
  padding: 24px;
  background: #e9ecf0;
  min-height: 720px;
  position: relative;
  border-top: 0;

  &.container::before {
    content: '';
    position: absolute;
    top: 0;
    left: -1px;
    width: 99.3%;
    height: 1px;
    background-color: #d3dae2;
  }

  &[data-has-after='true']::after {
    display: block;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: -1px;
    width: 99.3%;
    height: 1px;
    background-color: #d3dae2;
    display: none;
  }

  .title {
    color: black;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 18px */
    letter-spacing: -1px;
  }
  .explainTitle {
    color: #6c6c6c;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 18px */
    letter-spacing: -1px;
    margin-left: 8px;
  }
  .bodyContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    .bodyText {
      display: flex;
      align-items: center;
      color: #777c86;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 35px; /* 194.444% */
      letter-spacing: -1.25px;
      margin-bottom: 26px;
      .plusButton {
        display: flex;
        align-items: center;
        gap: 2px;
        border-radius: 25px;
        height: 28px;
        background: #00b4e3;
        padding: 6px 10px;
        color: #fff;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%; /* 14px */
        margin: 0 4px;
        .buttonIcon {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
}
.checkHeader {
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  .checkTitle {
    color: black;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 18px */
    letter-spacing: -1px;
  }
  .deleteBtn {
    color: #006ac3;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: -1.25px;
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-skip-ink: auto;
    text-decoration-thickness: auto;
    text-underline-offset: auto;
    text-underline-position: from-font;
  }
}

.subTitleWrapper {
  display: flex;
  align-items: center;
  margin-top: 12px;
  padding-bottom: 16px;
  border-bottom: 1px solid #c6cbd5;

  .purposeCount {
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 18px */
    letter-spacing: -1px;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 18px */
    letter-spacing: -1px;
  }
  .dot {
    margin-left: 10px;
    margin-right: 9px;
    width: 3px;
    height: 3px;
    background-color: #6c6c6c;
    border-radius: 50%;
  }
}

.answerList {
  margin-top: 16px;
  width: 100%;
  .businessPurposeAddBtn {
    width: 100%;
    height: 100%;
    background-color: transparent;
    color: #999fac;
    font-size: 20px;
    font-weight: 400;
    position: relative;
    padding: 19px 18px 20px;
    .addGray {
      position: relative;
      top: -2px;
      margin-left: 5px;
      width: 19px;
      height: 19px;
      vertical-align: middle;
    }
  }
}
.helperClass {
  box-shadow: 5px 5px 8px 5px rgba($color: #000000, $alpha: 0.1);
  z-index: 999999;
}
.sortableItem {
  position: relative;
  width: 470px;
  padding: 13px 16px;
  border: 1px solid #d3dae2;
  background: white;
  margin-left: 36px;

  &.deletable {
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 4px;
      height: 100%;
      background: #00b4e3;
    }
    .closeBtn {
      cursor: pointer;
      position: absolute;
      top: 18px;
      right: -36px;
      &.closeBtn::selection {
        background-color: transparent;
      }
    }
  }
  &.fixedText {
    // padding-left: 54px;
    color: #585a5f;
    font-size: 18px;
    font-weight: 400;
    line-height: 35px; /* 194.444% */
    letter-spacing: -1.25px;
    margin-right: 4px;
    .number {
      margin-right: 10px;
    }
  }
  .inputText,
  .businessPurposeNumber {
    display: inline-block;
    height: 22px;
    color: #000;
    font-size: 17px;
    font-weight: 400;
    letter-spacing: -0.5px;
    vertical-align: top;
  }
  .businessPurposeNumber {
    margin-top: 10px;
    margin-right: 10px;
  }
  .inputText {
    margin-top: 5px;
    margin-right: 14px;
    width: 412px;
    background-color: transparent;
    height: 35px;
    resize: none;
  }
  .sortBtn {
    display: inline-block;
    position: absolute;
    top: 27px;
    right: -32px;
  }
}
