.Modal {
  max-width: 90vw;
  max-height: 90vh;
  width: 600px;
  height: fit-content;
  overflow: auto;

  .content {
    height: 100%;
    background: #fff;

    .title {
      color: #1e1e22;
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;

      span {
        color: #006ac3;
        font-weight: 700;
      }
    }

    .text {
      margin-top: 24px;

      color: #8c8c8c;
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;

      span {
        color: #1e1e22;
      }
    }

    .buttonWrapper {
      margin-top: 48px;

      display: flex;
      flex-direction: column;
      gap: 12px;

      width: 100%;

      .button {
        width: 100%;
        height: 52px;

        padding: 13px 0px 15px 0px;

        display: flex;
        justify-content: center;
        align-items: center;

        border-radius: 4px;

        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 30px */

        &Yes {
          @extend .button;
          background: #006ac3;
          color: #fff;
        }

        &No {
          @extend .button;

          background: #fff;
          border: 1px solid #006ac3;
          color: #006ac3;
        }
      }
    }
  }
}
